<template>
  <div id="playlist-modal">
    <b-icon
      class="close-icon"
      icon="x"
      font-scale="1.5"
      title="Close"
      @click="$emit('close')"
    ></b-icon>
    <h3 class="text-center">{{ playlist.name }}</h3>
    <b-row>
      <b-col cols="12" lg="4" xl="4">
        <div class="text-center">
          <img
            class="img-fluid playlist-img"
            :src="playlist.photo"
            alt="Play List, Thumbnail"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="8" xl="8" class="my-auto">
        <b-overlay :show="loadingSongs">
          <div class="songs" v-if="songs.length > 0">
            <div
              class="song-container"
              :class="song.is_free === 0 && subscription.class_name === 'free' ?
                'premium' : ''"
              v-for="(song, index) in songs"
              :key="song.mobile_music_id">

              <!-- Premium -->
              <!-- <div
                class="mb-2"
                v-if="song.is_free === 0 && subscription.class_name === 'free'"
                @click="premiumDialog"> -->
              <div
                class="mb-2"
                v-if="song.is_free === 0 && subscription.class_name === 'free'"
                @click="showPremiumModal('song')">
                <b-row align-v="center" no-gutters>
                  <b-col cols="1">
                    <img
                      class="img-fluid lock"
                      src="../../assets/images/music/lock.png"
                      alt="Lock"
                      title="Locked"
                    />
                  </b-col>
                  <b-col cols="11">
                    <p
                      class="song-name"
                      title="Premium Song">
                      {{ song.music_title | truncate(25) }}
                    </p>
                  </b-col>
                </b-row>

                <b-overlay
                  class="overlay"
                  variant="transparent"
                  :opacity="0.5"
                  show>
                  <template #overlay>
                    <i class="fas fa-lock" style="color: transparent;"></i>
                  </template>
                  <vue-plyr
                    style="display:block;"
                    :key="song.url"
                    ref="plyr"
                    :options="{
                      listeners : {
                        play : checkSong,
                        seek : checkSong,
                      },
                    }">
                    <audio preload="none"></audio>
                  </vue-plyr>
                </b-overlay>
              </div>

              <!-- Free -->
              <div class="mb-2" v-else>
                <b-row align-v="center" no-gutters>
                  <b-col cols="1">
                    <img
                      class="img-fluid heart"
                      src="../../assets/images/music/heart-selected.png"
                      alt="Heart"
                      title="Favorite"
                      @click="removeFavorite(song.mobile_music_id)"
                      v-if="song.favorite === 1"
                    />
                    <img
                      class="img-fluid heart"
                      src="../../assets/images/music/heart.png"
                      alt="Heart"
                      title="Favorite"
                      @click="addFavorite(song.mobile_music_id)"
                      v-else
                    />
                  </b-col>
                  <b-col cols="11">
                    <p class="song-name">
                      {{ song.music_title | truncate(25) }}
                    </p>
                  </b-col>
                </b-row>

                <vue-plyr
                  :class="`${index}`"
                  :key="song.url"
                  :ref="`player${index}`">
                  <audio>
                    <source
                      :src="song.url"
                      type="audio/mp3"
                    />
                  </audio>
                </vue-plyr>
              </div>
            </div>
          </div>
          <p class="text-center text-white mt-3" v-else>
            <span v-if="playlist.id === 0">No Favorites</span>
            <span v-else>No Songs</span>
          </p>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import FilterMixin from '@/mixins/FilterMixin';
  import LevelUpMixin from '@/mixins/LevelUpMixin';
  import PremiumMixin from '@/mixins/PremiumMixin';
  
  export default {
    name   : 'SongListModal',
    mixins : [
      FilterMixin,
      LevelUpMixin,
      PremiumMixin,
    ],
    props : {
      playlist : {
        type     : Object,
        required : true,
      },
    },
    data() {
      return {
        pSongs        : [],
        loadingSongs  : false,
        activeSong    : {},
        activeIndex   : null,
        totalPlayed   : 0,
        status        : null,
        player        : null,
        currentTime   : null,
        streamCount   : 0,
        thirtySecSent : false,
        totalSecSent  : false,
      }
    },
    computed : {
      ...mapGetters({
        profile        : 'profile/info',
        musicFavorites : 'profile/musicFavorites',
        subscription   : 'subscription/subscription',
      }),
      songs() {
        let songs = [];

        if (this.playlist.id === 0)
          songs = this.$store.getters['profile/musicFavorites'];
        else
          songs = this.pSongs;

        return songs;
      },
    },
    watch : {
      currentTime(newVal, oldVal) {
        let diff = 0;
        if (newVal > oldVal)
          diff = newVal - oldVal;
        else
          diff = oldVal - newVal;
        
        if (diff < 1 && this.status === 'playing')
          this.totalPlayed = this.totalPlayed + diff;
      },
    },
    methods : {

      /**
       * Add Song To Favorites
       * @param id
       */
      addFavorite(id) {
        const params = { musicId : id };
        if (this.profile.user_profiles_id)
          params.profileId = this.profile.user_profiles_id;
        
        this.$http.post('api/mymusic', params)
          .then(() => {
            this.$store.dispatch('profile/getMusicFavorites');
            if (this.playlist.id !== 0)
              this.getPlaylistSongs(0);
          })
          .catch(() => {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed!',
              text  : 'Oops! Something went wrong.',
            });
          });
      },

      /**
       * Remove Song From Favorites
       * @param id
       */
      removeFavorite(id) {
        const params = { musicId : id };
        if (this.profile.user_profiles_id)
          params.profileId = this.profile.user_profiles_id;
        this.$http.delete('api/mymusic', {
          data : params,
        }).then(() => {
          this.$store.dispatch('profile/getMusicFavorites');
          if (this.playlist.id !== 0)
            this.getPlaylistSongs(0);
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        })
      },

      /**
       * Get Playlist Songs
       * @param currentPage
       */
      getPlaylistSongs(currentPage = this.songs.currentPage) {
        this.loadingSongs = true;
        const params = {
          plistId : this.playlist.id,
          page    : currentPage + 1,
          perPage : 1000,
        };

        if (this.profile.user_profiles_id)
          params.profileId = this.profile.user_profiles_id;
        
        this.$http.get('api/playlist/songs', {
          params : params,
        }).then(response => {
          this.pSongs = response.data.result.data;
        }).catch(() => {
          this.pSongs = [];
        }).finally(() => {
          this.loadingSongs = false;
        });
      },

      /**
       * Check Song
       */
      checkSong(e) {
        e.preventDefault();
        return false;
      },

      /**
       * Start Stream
       */
      startStream() {
        this.$http.post('api/access/startStream')
          .then(response => {
            if (response.data.code == '0002') {
              // this.player.pause();
              // this.$notify({
              //   group : 'notif',
              //   type  : 'error',
              //   title : 'Unable to Play',
              //   text  : 'Number of concurrent media streaming for this ' +
              //     'account has been exceeded.',
              // });
            }
          });
      },

      /**
       * Stop Stream
       */
      stopStream() {
        this.$http.post('api/access/stopStream');
      },

      /**
       * Get Stream Count
       */
      getStreamCount() {
        this.$http.get('api/access/streamCount').then(ret => {
          this.streamCount = ret.data.data;
        });
      },

      /**
       * Play Next Song in the list
       */
      playNextSong() {
        const audios = document.getElementsByTagName('audio');
        const nextIndex = this.activeIndex + 1;
        if (this.songs.length > nextIndex)
          audios[nextIndex].play();
      },
      gtag30sec(){
        this.$gtag.event('music_30s_listen', {
          'media_id'    : this.activeSong.mobile_music_id,
          'media_title' : this.activeSong.music_title,
          'platform'    : 'website',
          'environment' : process.env.VUE_APP_ENVIRONMENT,
        })
      },
      gtagtotalsec(){
        this.$gtag.event('music_total_listen', {
          'media_id'              : this.activeSong.mobile_music_id,
          'media_title'           : this.activeSong.music_title,
          'platform'              : 'website',
          'environment'           : process.env.VUE_APP_ENVIRONMENT,
          'music_listen_duration' : this.totalPlayed,
        })
      },
      gtagStartListen(){
        this.$gtag.event('music_start_listen', {
          'media_id'    : this.activeSong.mobile_music_id,
          'media_title' : this.activeSong.music_title,
          'platform'    : 'website',
          'environment' : process.env.VUE_APP_ENVIRONMENT,
        })
      },
    },
    created() {
      window.addEventListener('beforeunload', e => {
        if(this.totalPlayed >= 30){
          if(!this.thirtySecSent)
            this.gtag30sec();
        }

        if(!this.totalSecSent)
          this.gtagtotalsec()

        //this.stopStream();
        delete e['returnValue'];
      });
    },
    mounted() {
      if (this.playlist.id !== 0)
        this.getPlaylistSongs(0);
      
      this.$store.dispatch('profile/getMusicFavorites');

      document.addEventListener('timeupdate', e => {
        const currAudio = e.target.getElementsByTagName('audio')[0];
        this.currentTime = currAudio.currentTime;
      });

      document.addEventListener('playing', e => {
        //   console.log("...PLAYING triggered...");
        this.gtagStartListen();

        this.status = 'playing';

        //this.startStream();
        const currAudio = e.target.getElementsByTagName('audio')[0];
        const audios = document.getElementsByTagName('audio');
        this.player = currAudio;

        for (let i = 0; i < audios.length; i++) {
          if (audios[i] == currAudio && this.activeSong != this.songs[i]) {
            this.activeIndex = i;
            this.activeSong = this.songs[i];
            this.totalPlayed = 0;

            this.$analytics.fbq.event('ContentView', {
              'page'        : 'music-player',
              'platform'    : 'website',
              'music_title' : this.activeSong.music_title,
              'music_id'    : this.activeSong.mobile_music_id,
            });
          } else if (audios[i] != currAudio)
            audios[i].pause();
        }
      }, false);

      document.addEventListener('pause', () => {
        //  console.log("...PAUSE triggered...");
        this.status = 'pause';
        
        //this.stopStream();
      }, false);

      document.addEventListener('seeking', () => {
        // console.log("...SEEKING triggered...");
        this.status = 'seeking';
      }, false);

      document.addEventListener('ended', () => {
        //  console.log("...ENDED triggered...");
        this.status = 'ended';
        
        //this.stopStream();
        if(this.totalPlayed >= 30){
          if(!this.thirtySecSent)
            this.gtag30sec();
        }

        if(!this.totalSecSent)
          this.gtagtotalsec();

        if (this.totalPlayed >= 60)
          this.addPoints('LISTENED_MUSIC');

        this.playNextSong();
      }, false);

      document.addEventListener('loadeddata', () => {
        this.$analytics.fbq.event('ContentView', {
          'page'          : 'playlist-music-modal',
          'platform'      : 'website',
          'playlist_name' : this.playlist.name,
          'playlist_id'   : this.playlist.id,
        })
      }, false);
    },
    beforeDestroy() {
      //this.stopStream();
      if(this.totalPlayed >= 30){
        if(!this.thirtySecSent)
          this.gtag30sec();
      }

      if(!this.totalSecSent)
        this.gtagtotalsec();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #playlist-modal {
    padding: 1.5rem 2rem;
    background-color: rgba(0, 129, 207, 0.9);
  }

  .close-icon {
    float: right;
    color: #fff;
    &:hover {
      cursor: pointer;
    }
  }

  h3 {
    margin-bottom: 1.5rem;
    color: #fff !important;
    font-family: "WickedMouse" !important;
    font-size: 1.75em !important;
    -webkit-text-stroke: transparent !important;
  }

  .songs {
    padding-right: 1rem;
    max-height: 63vh;
    overflow-y: auto;
  }

  .song-name, .link {
    margin-bottom: 0;
    margin-right: 0.5rem;
    padding-top: 0.5rem;
    color: #fff;
    font-family: "WickedMouse" !important;
    font-size: 0.9em !important;
    letter-spacing: 1.5px;
  }

  .link {
    &:hover {
      cursor: pointer;
      text-decoration: none;
      text-shadow: 3px 3px 10px rgba(0,0,0,0.5);
    }
  }

  .duration {
    margin-bottom: 0;
    color: #fff;
    font-size: 0.9em !important;
    text-align: right;
  }

  .playlist-img {
    width: 100%;
    height: auto; //16rem;
    border: 3px solid #e7932d;
    border-radius: 0.75rem;
  }

  .song-container {
    margin-bottom: 1rem;
    padding: 0.5rem;
    padding-bottom: 0;
    border: 5px solid #e7932d;
    border-radius: 0.75rem;
  }

  .heart {
    width: 60%;
    &:hover {
      cursor: pointer;
    }
  }

  .lock {
    width: 60%;
  }

  .premium {
    border: 5px solid #A9A9A9;
    border-radius: 0.75rem;
    &:hover {
      cursor: pointer;
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    h3 {
      margin-bottom: 1rem;
    }
    .playlist-img {
      margin-bottom: 1rem;
      width: 55%;
    }
    .heart {
      width: 80%;
    }
    .lock {
      width: 80%;
    }
    .songs {
      padding-right: 0;
      max-height: 50vh;
    }
    .song-container {
      border: 3px solid #e7932d;
    }
    .song-name {
      padding-left: 0.5rem;
      padding-top: 0.2rem;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .playlist-img {
      margin-bottom: 1rem;
      width: 55%;
    }
    .songs {
      padding-right: 0;
      max-height: 50vh;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .playlist-img {
      margin-bottom: 1rem;
      width: 50%;
    }
    .heart {
      width: 55%;
    }
    .lock {
      width: 55%;
    }
    .song-name {
      padding-left: 0;
      padding-top: 0.2rem;
      font-size: 1em !important;
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .playlist-img {
      margin-bottom: 1rem;
      width: 100%;
    }
    .songs {
      padding: 0 1rem;
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {}

  @media only screen and (min-width: $special) {
    .songs {
      padding: 0 0.75rem;
      max-height: 65vh;
    }
  }

  @media only screen and (min-width: $xl2-min) {
    .songs {
      padding: 0 0.8rem;
      max-height: 65vh;
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .songs {
      padding: 0 0.75rem;
      max-height: 65vh;
    }
    .song-name {
      padding-top: 0.5rem;
      font-size: 0.9em !important;
    }
  }
</style>