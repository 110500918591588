var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"playlist-modal"}},[_c('b-icon',{staticClass:"close-icon",attrs:{"icon":"x","font-scale":"1.5","title":"Close"},on:{"click":function($event){return _vm.$emit('close')}}}),_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.playlist.name))]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4","xl":"4"}},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"img-fluid playlist-img",attrs:{"src":_vm.playlist.photo,"alt":"Play List, Thumbnail"}})])]),_c('b-col',{staticClass:"my-auto",attrs:{"cols":"12","lg":"8","xl":"8"}},[_c('b-overlay',{attrs:{"show":_vm.loadingSongs}},[(_vm.songs.length > 0)?_c('div',{staticClass:"songs"},_vm._l((_vm.songs),function(song,index){return _c('div',{key:song.mobile_music_id,staticClass:"song-container",class:song.is_free === 0 && _vm.subscription.class_name === 'free' ?
              'premium' : ''},[(song.is_free === 0 && _vm.subscription.class_name === 'free')?_c('div',{staticClass:"mb-2",on:{"click":function($event){return _vm.showPremiumModal('song')}}},[_c('b-row',{attrs:{"align-v":"center","no-gutters":""}},[_c('b-col',{attrs:{"cols":"1"}},[_c('img',{staticClass:"img-fluid lock",attrs:{"src":require("../../assets/images/music/lock.png"),"alt":"Lock","title":"Locked"}})]),_c('b-col',{attrs:{"cols":"11"}},[_c('p',{staticClass:"song-name",attrs:{"title":"Premium Song"}},[_vm._v(" "+_vm._s(_vm._f("truncate")(song.music_title,25))+" ")])])],1),_c('b-overlay',{staticClass:"overlay",attrs:{"variant":"transparent","opacity":0.5,"show":""},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('i',{staticClass:"fas fa-lock",staticStyle:{"color":"transparent"}})]},proxy:true}],null,true)},[_c('vue-plyr',{key:song.url,ref:"plyr",refInFor:true,staticStyle:{"display":"block"},attrs:{"options":{
                    listeners : {
                      play : _vm.checkSong,
                      seek : _vm.checkSong,
                    },
                  }}},[_c('audio',{attrs:{"preload":"none"}})])],1)],1):_c('div',{staticClass:"mb-2"},[_c('b-row',{attrs:{"align-v":"center","no-gutters":""}},[_c('b-col',{attrs:{"cols":"1"}},[(song.favorite === 1)?_c('img',{staticClass:"img-fluid heart",attrs:{"src":require("../../assets/images/music/heart-selected.png"),"alt":"Heart","title":"Favorite"},on:{"click":function($event){return _vm.removeFavorite(song.mobile_music_id)}}}):_c('img',{staticClass:"img-fluid heart",attrs:{"src":require("../../assets/images/music/heart.png"),"alt":"Heart","title":"Favorite"},on:{"click":function($event){return _vm.addFavorite(song.mobile_music_id)}}})]),_c('b-col',{attrs:{"cols":"11"}},[_c('p',{staticClass:"song-name"},[_vm._v(" "+_vm._s(_vm._f("truncate")(song.music_title,25))+" ")])])],1),_c('vue-plyr',{key:song.url,ref:`player${index}`,refInFor:true,class:`${index}`},[_c('audio',[_c('source',{attrs:{"src":song.url,"type":"audio/mp3"}})])])],1)])}),0):_c('p',{staticClass:"text-center text-white mt-3"},[(_vm.playlist.id === 0)?_c('span',[_vm._v("No Favorites")]):_c('span',[_vm._v("No Songs")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }